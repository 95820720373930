import { Card, Empty } from 'antd';
import { ReactNode } from 'react';

export const ProEmpty: React.FC<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  children: ReactNode;
}> = ({ data, children }) => {
  return (
    <>
      {data && data?.length > 0 ? (
        children
      ) : (
        <Card>
          <Empty />
        </Card>
      )}
    </>
  );
};
