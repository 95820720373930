import { useRequest } from 'ahooks';
import { Form, Input, message, Modal, Select } from 'antd';
import { UpdateUserDto } from 'auth/auth.interface';
import { getUser, updateUser, userRoles } from 'auth/auth.service';
import { ProSpin } from 'pro';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { Dispatch, SetStateAction, useState } from 'react';
const { Option } = Select;

const UpdateUserModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  refresh: () => void;
  id: number;
}> = ({ open, setOpen, refresh, id }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateUser, { manual: true });
  const { data } = useRequest(userRoles);
  const { loading: ld } = useRequest(getUser, {
    defaultParams: [id],
    onSuccess: (v) => {
      form.setFieldsValue(v);
      form.setFieldsValue({ roleIds: v.roles.map((r) => r.id) });
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: UpdateUserDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      message.success('修改成功!');
      onCancel();
      refresh();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="编辑"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <ProSpin loading={ld}>
        <Form
          name="update-user-form"
          form={form}
          preserve={false}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
        >
          <FormAlert {...alert} />
          <Form.Item name="username" label="用户名">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            name="phone"
            label="手机号"
            rules={[
              { required: true, message: ' 请输入手机号!' },
              {
                required: false,
                pattern: new RegExp(/^1(3|4|5|6|7|8|9)\d{9}$/, 'g'),
                message: '请输入正确的手机号！',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="name" label="姓名">
            <Input />
          </Form.Item>
          <Form.Item
            name="roleIds"
            label="角色"
            rules={[{ required: false }]}
            wrapperCol={{ span: 16 }}
          >
            <Select mode="multiple" placeholder="选择您的角色">
              {data &&
                data.map((r) => (
                  <Option key={r.id} value={r.id}>
                    {r.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </ProSpin>
    </Modal>
  );
};

export default UpdateUserModal;
