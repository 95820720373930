import { message } from 'antd';
import { getAuthorization } from 'auth/AuthProvider';
import {
  CAPTCHA_CODE_URL,
  LOGIN2_URL,
  LOGIN_URL,
  REGISTER2_URL,
  REGISTER_URL,
} from 'auth/auth.service';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { router } from 'config/router';
import { apiPrefix } from './url.help';

declare module 'axios' {
  export interface AxiosRequestConfig {
    /**
     * 手动处理错误
     */
    handlerErrorManually?: boolean;
  }
}

interface BusinessError {
  type: string;
  errorCode: string;
  errorMessage: string;
  reason: string;
}

interface CommonError {
  statusCode: number;
  message: string;
  error: string;
}

/*
一个自定义的 axios 实例供调用
- 支持请求鉴权
- 支持公共错误处理
*/
const instance = axios.create({});

//https://stackoverflow.com/a/72005814
const cancel = (config: AxiosRequestConfig) => {
  const controller = new AbortController();
  const cfg = {
    ...config,
    signal: controller.signal,
  };
  controller.abort('We gotta cancel this');
  return cfg;
};

/**
 * 为 Http 请求附加 token
 */
instance.interceptors.request.use(
  (config) => {
    // 登录请求不处理
    // 其他情况检查token
    if (
      config.url === apiPrefix(LOGIN_URL) ||
      config.url === apiPrefix(LOGIN2_URL) ||
      config.url === apiPrefix(CAPTCHA_CODE_URL) ||
      config.url === apiPrefix(REGISTER_URL) ||
      config.url === apiPrefix(REGISTER2_URL)
    ) {
      return config;
    } else {
      // 检查 token
      const auth = getAuthorization();
      if (!auth || auth === '') {
        //跳转到登录页面
        router.navigate(LOGIN_URL, { replace: true });
        //cancal request
        return cancel(config);
      }
      // 附加 token
      config.headers = {
        Authorization: auth,
      };
      return config;
    }
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (res) => res,
  (err) => {
    /*
      全局错误处理
      1. 处理 404 错误
      2. 处理登录失败(401)错误
      3. 处理接口鉴权失败(其他401)错误
      4. 处理500错误
      5. 处理其他错误
    */
    console.log(err);
    console.log(err.config.handlerErrorManually);
    if (axios.isAxiosError(err)) {
      if (err.response) {
        // 一般情况下，错误会转化，并在全局提示错误信息
        // 但 form 请求时的错误应优先在 form 中提醒
        // 所以，根据错误代码可以挑选 form 错误以特别处理
        // 对于转化失败的错误，提供友好的错误信息。
        // business error 是已经明确定义的错误消息

        // 不再使用错误代码区分错误
        // 直接为 request 标识 handlerErrorManually = true

        // 常见的 404 全局错误
        const statusCode = err.response.status;
        if (statusCode === 404) {
          const comErr = err.response.data as CommonError;
          if (comErr) {
            message.error(comErr.message);
            return Promise.resolve(1);
          }
        }
        // 登录from 的错误消息
        if (
          statusCode === 401 &&
          (err.config.url === apiPrefix(LOGIN_URL) ||
            err.config.url === apiPrefix(LOGIN2_URL))
        ) {
          const busErr = err.response.data as BusinessError;
          if (busErr && err.config.handlerErrorManually) {
            return Promise.reject(busErr.errorMessage);
          }
        }

        // 接口鉴权失败
        if (statusCode === 401) {
          router.navigate(LOGIN_URL, { replace: true });
          //history.replace(LOGIN_URL); //跳转到登录页面
          message.error('登录失效，请重新登录！');
          return Promise.resolve(1);
        }

        if (statusCode === 500) {
          const busErr = err.response.data as BusinessError;
          if (busErr) {
            // 修改密码等商业性form 的错误消息
            if (
              busErr.type === 'business-error' &&
              err.config.handlerErrorManually
            ) {
              return Promise.reject(busErr.errorMessage);
            }

            // 其他的已定义错误消息
            if (busErr.type === 'business-error') {
              message.error(busErr.errorMessage);
              // 错误的删除了管理员，被后台拒绝
              return Promise.resolve(1);
            }
          }
          // 未定义的错误消息，包括服务器离线、后端未定义异常等
          err.config.handlerErrorManually &&
            message.error('500错误, 请联系管理员！');
          return Promise.reject('出现错误, 请联系管理员！');
        }

        // dto 验证错误(400) 数据库错误(500)
        if (err.config.handlerErrorManually) {
          return Promise.reject('出现错误, 请联系管理员！');
        }

        // 不明原因的错误消息，全局提醒友好消息
        // 例如：504 服务器超时错误
        message.error('出现错误, 请联系管理员！');
        return Promise.resolve(1);
      }
    }
    return Promise.reject(err);
  }
);

export default instance;

/**
 * 提取 AxiosResponse 的 data
 */
export async function getData<T>(
  axiosRequest: Promise<AxiosResponse<T, unknown>>
): Promise<T> {
  const res = await axiosRequest;
  return res.data;
}
