import { useRequest } from 'ahooks';
import { Form, Input, message, Modal } from 'antd';
import { FormAlert, FormAlertProps, getErrorMessage, ProSpin } from 'pro';
import { Dispatch, SetStateAction, useState } from 'react';
import { UpdateProjectDto } from '../projects.interface';
import { getProject, updateProject } from '../projects.service';
const { TextArea } = Input;

const UpdateProjectModal: React.FC<{
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  id: number;
}> = ({ open, setOpen, onSuccess, id }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateProject, { manual: true });
  const { loading: getOneLoading } = useRequest(getProject, {
    defaultParams: [id],
    onSuccess: (v) => {
      form.setFieldsValue(v);
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: UpdateProjectDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      message.success('修改成功!');
      onCancel();
      onSuccess();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="编辑项目"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <ProSpin loading={getOneLoading}>
        <Form
          form={form}
          preserve={false}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
        >
          <FormAlert {...alert} />
          <Form.Item name="name" label="项目名称" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="note" label="备注">
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </ProSpin>
    </Modal>
  );
};

export default UpdateProjectModal;
