import * as echarts from 'echarts';
import { EChartsOption } from 'echarts';
import { useEffect, useRef } from 'react';
import { RecordItemDto } from '../records.interface';

const isZeroOrNotNull = (val: number | undefined) => {
  if (val === 0) {
    return true;
  } else {
    return val;
  }
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const keepHighestTwoDigits = (num: number) => {
  if (num === 0) return 0; // 处理零的特殊情况

  num = Math.abs(num) * 1.5;

  let isNegative = num < 0;
  if (isNegative) {
    num = Math.abs(num);
  }

  let exponent = Math.floor(Math.log10(num));
  let scale = Math.pow(10, exponent - 1);
  let scaledNum = num / scale;

  let firstTwoDigits = Math.floor(scaledNum * 100) / 100;
  let result = firstTwoDigits * scale;

  if (isNegative) {
    result = -result;
  }

  return result;
};

const ReportChart: React.FC<{ reportItems?: RecordItemDto[] }> = ({
  reportItems,
}) => {
  const chartRef = useRef(null);
  const isDouble = reportItems?.some((e) => isZeroOrNotNull(e.b));
  useEffect(() => {
    if (!reportItems) {
      throw new Error('报表为空');
    }
    const ca = reportItems
      .map((p) => p.laac)
      .concat(
        reportItems.map((p) => p.aac),
        reportItems.map((p) => p.labc),
        reportItems.map((p) => p.abc)
      )
      .filter(notEmpty);
    const max = Math.max(...ca, Math.abs(Math.min(...ca)));
    const newmax = keepHighestTwoDigits(max);
    var echart = echarts.getInstanceByDom(
      chartRef.current as unknown as HTMLElement
    );
    if (!echart) {
      echart = echarts.init(chartRef.current);
    }
    var option: EChartsOption = isDouble
      ? {
          title: [
            {
              right: '60%',
              text: '监测深度-位移变化曲线图(A轴)',
            },
            {
              left: '60%',
              text: '监测深度-位移变化曲线图(B轴)',
            },
          ],
          tooltip: {
            trigger: 'axis',
          },
          xAxis: [
            {
              name: '变化量(mm)',
              nameLocation: 'middle',
              nameGap: 24,
              splitLine: { show: false },
              type: 'value',
              max: newmax,
              min: -newmax,
            },
            {
              name: '变化量(mm)',
              nameLocation: 'middle',
              nameGap: 24,
              type: 'value',
              splitLine: { show: false },
              gridIndex: 1,
              max: newmax,
              min: -newmax,
            },
          ],
          yAxis: [
            {
              name: '深度(m)',
              type: 'category',
              axisTick: { show: false },
              data: reportItems?.map((p) => p.depth),
              axisLabel: {
                formatter: '{value} m',
              },
            },
            {
              name: '深度(m)',
              type: 'category',
              axisTick: { show: false },
              data: reportItems?.map((p) => p.depth),
              gridIndex: 1,
              axisLabel: {
                formatter: '{value} m',
              },
            },
          ],
          grid: [
            {
              right: '60%',
            },
            {
              left: '60%',
            },
          ],
          legend: {
            left: 'center',
            bottom: 10,
          },
          series: [
            {
              name: '上次累计变化量(mm)',
              data: reportItems?.map((p) => p.laac),
              type: 'line',
              xAxisIndex: 0,
              yAxisIndex: 0,
            },
            {
              name: '本次累计变化量(mm)',
              data: reportItems?.map((p) => p.aac),
              type: 'line',
              xAxisIndex: 0,
              yAxisIndex: 0,
            },
            {
              name: '上次累计变化量(mm)',
              data: reportItems?.map((p) => p.labc),
              type: 'line',
              xAxisIndex: 1,
              yAxisIndex: 1,
            },
            {
              name: '本次累计变化量(mm)',
              data: reportItems?.map((p) => p.abc),
              type: 'line',
              xAxisIndex: 1,
              yAxisIndex: 1,
            },
          ],
        }
      : {
          title: {
            text: '监测深度-位移变化曲线图(A轴)',
            left: 'center',
          },
          tooltip: {
            trigger: 'axis',
          },
          xAxis: {
            name: '变化量(mm)',
            nameLocation: 'middle',
            nameGap: 24,
            splitLine: { show: false },
            type: 'value',
            max: 2 * newmax,
            min: -2 * newmax,
          },
          yAxis: {
            name: '深度(m)',
            type: 'category',
            axisTick: { show: false },
            data: reportItems?.map((p) => p.depth),
            axisLabel: {
              formatter: '{value} m',
            },
          },
          legend: {
            left: 'center',
            bottom: 10,
          },
          series: [
            {
              name: '上次累计变化量(mm)',
              data: reportItems?.map((p) => p.laac),
              type: 'line',
              xAxisIndex: 0,
              yAxisIndex: 0,
            },
            {
              name: '本次累计变化量(mm)',
              data: reportItems?.map((p) => p.aac),
              type: 'line',
              xAxisIndex: 0,
              yAxisIndex: 0,
            },
          ],
        };
    echart.setOption(option);
  }, [reportItems]);

  return (
    <div ref={chartRef} style={{ height: '620px', marginTop: '16px' }}></div>
  );
};

export default ReportChart;
