import {
  DownOutlined,
  EditOutlined,
  PoweroffOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu, MenuProps, Space, Spin } from 'antd';
import { useAuth } from 'auth/AuthProvider';
import { User } from 'auth/auth.interface';
import { LOGIN_URL } from 'auth/auth.service';
import { MenuDataType, menuData } from 'config/menu.data';
import { setPageTitle } from 'config/useTitleHook';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ChangePasswordModal from './ChangePassword';
import './index.css';
const { Header, Sider, Content } = Layout;
type MenuItem = Required<MenuProps>['items'][number];

const LayoutFc: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [initState, setInitState] = useState<{
    loading: boolean;
    menu?: MenuItem[];
    user?: User;
  }>({ loading: true });
  const [collapsed, setCollapsed] = useState(false);

  // 修改密码
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const initSys = async () => {
      // 2. 获取当前用户
      const user = await auth.getUser();
      setInitState((e) => ({ ...e, user }));
      const userPerms = Array.from(
        new Set(user?.roles?.flatMap((r) => r.permissions))
      ).filter((x): x is string => x !== undefined);

      // 3. 获取并设置菜单
      // 本系统无需根据权限动态生成菜单
      const getItems: (
        items: MenuDataType[] | undefined
      ) => MenuItem[] | undefined = (items) => {
        if (!items) return undefined;

        if (!user?.isDefault) {
          items = items.filter(
            (i) => i.permission && userPerms.includes(i.permission)
          );
        }
        return items.map(
          (i) =>
            ({
              key: i.path,
              label: i.name,
              icon: i.icon,
              children: getItems(i.children),
            } as MenuItem)
        );
      };
      const items = getItems(menuData);
      setInitState((e) => ({ ...e, menu: items }));

      // 4. 设置页标题
      setPageTitle(location.pathname);

      // 5. 呈现主页
      setInitState((e) => ({ ...e, loading: false }));
    };

    // 1. 认证校验
    if (!auth.isAuth) {
      navigate(LOGIN_URL, { replace: true, state: { from: location } });
    } else {
      initSys();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set defaultSelectedKeys defaultOpenKeys
  const d = location.pathname.split('/').filter((e) => e);
  const s = d.pop();
  const defaultSelectedKeys = s ? [s] : ['dashboard'];
  const defaultOpenKeys = d.length > 0 ? d : undefined;

  return (
    <>
      {initState.loading ? (
        <div className="sysinit-loading">
          <Spin size="large" />
        </div>
      ) : (
        <div
        // style={{
        //   backgroundImage: `url("https://bing.img.run/1920x1080.php")`,
        //   backgroundSize: '100% 100%',
        // }}
        >
          <div className="bg">
            <Layout className="tran">
              <Header
                className="tran"
                style={{
                  display: 'flex',
                  padding: '0',
                }}
              >
                <div className="logo">
                  <a href="/">
                    <img
                      src={process.env.PUBLIC_URL + '/logo.png'}
                      style={{ width: '150px' }}
                      alt="星链感知"
                    ></img>
                  </a>
                </div>
                <div
                  style={{
                    flexGrow: 1,
                  }}
                >
                  {/* <Menu
                    style={{
                      border: 'none',
                      marginInlineStart: '8px', //24px - 16px
                    }}
                    mode="horizontal"
                    className="tran"
                    defaultSelectedKeys={['2']}
                    items={new Array(15).fill(null).map((_, index) => {
                      const key = index + 1;
                      return {
                        key,
                        label: `nav ${key}`,
                      };
                    })}
                  /> */}
                </div>
                <div>
                  <Space className="site-layout-second-action">
                    <Dropdown
                      overlay={
                        <Menu
                          items={[
                            {
                              key: '1',
                              label: (
                                <a
                                  href="/"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    setOpen(true);
                                  }}
                                >
                                  <EditOutlined /> 修改密码
                                </a>
                              ),
                            },
                            {
                              type: 'divider',
                            },
                            {
                              key: '2',
                              label: (
                                <a
                                  href="/"
                                  onClick={async (e) => {
                                    e.preventDefault();
                                    await auth.logout();
                                    navigate(LOGIN_URL);
                                  }}
                                >
                                  <PoweroffOutlined /> 登出
                                </a>
                              ),
                            },
                          ]}
                        />
                      }
                    >
                      <Button type="text">
                        <UserOutlined />{' '}
                        {initState.user?.username || initState.user?.phone}{' '}
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                  </Space>
                  {open ? (
                    <ChangePasswordModal {...{ open, setOpen }} />
                  ) : (
                    <></>
                  )}
                </div>
              </Header>
              <Layout className="tran">
                <Sider width={200} className="tran site-side">
                  <Menu
                    className="site-side-menu "
                    defaultSelectedKeys={defaultSelectedKeys}
                    defaultOpenKeys={defaultOpenKeys}
                    mode="inline"
                    items={initState.menu}
                    onClick={({ key }) => {
                      for (const i of menuData) {
                        if (i.path === key) {
                          navigate(`${i.path}`);
                          setPageTitle(`${i.path}`);
                          break;
                        } else if (i.children) {
                          for (const j of i.children) {
                            if (j.path === key) {
                              navigate(`${i.path}/${j.path}`);
                              setPageTitle(`${i.path}/${j.path}`);
                              break;
                            } else if (j.children) {
                              for (const k of j.children) {
                                if (k.path === key) {
                                  navigate(`${i.path}/${j.path}/${k.path}`);
                                  setPageTitle(`${i.path}/${j.path}/${k.path}`);
                                  break;
                                }
                              }
                            }
                          }
                        }
                      }
                    }}
                  />
                </Sider>
                <Layout className="tran">
                  <Content
                    className="site-layout-background"
                    style={{
                      padding: '4px 24px',
                      height: 0,
                      overflow: 'auto',
                    }}
                  >
                    <Outlet />
                  </Content>
                </Layout>
              </Layout>
            </Layout>
          </div>
        </div>
      )}
    </>
  );
};
export default LayoutFc;
