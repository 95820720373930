import { useAntdTable } from 'ahooks';
import { Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { User } from 'auth/auth.interface';
import { deleteUser, getUsers } from 'auth/auth.service';
import { ProBreadcrumb } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import CreateUserModal from './Create';
import ResetPasswordModal from './ResetPassword';
import UpdateUserModal from './Update';
import './index.css';

const UserFc: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getUsers, {
    //refreshOnWindowFocus: true,
  });
  const [openReset, setOpenReset] = useState(false);
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [userId, setUserId] = useState(0);

  const columns: ColumnsType<User> = [
    {
      title: '用户名',
      dataIndex: 'username',
    },
    {
      title: '姓名',
      dataIndex: 'name',
    },
    {
      title: '手机号',
      dataIndex: 'phone',
    },
    {
      title: '角色',
      render: (_, { roles }) => (
        <>
          {roles
            ? roles.map((role) => {
                return <Tag key={role.id}>{role.name}</Tag>;
              })
            : '-'}
        </>
      ),
    },
    {
      title: '操作',
      render: (_, { id, isDefault }) => (
        <Space size="middle">
          {!isDefault && (
            <UpdateButton
              onUpdate={() => {
                setUserId(id);
                setUpdateState(true);
              }}
            />
          )}
          <a
            href="/"
            onClick={async (e) => {
              e.preventDefault();
              setUserId(id);
              setOpenReset(true);
            }}
          >
            重置密码
          </a>
          {!isDefault && (
            <DeleteButton onDel={() => deleteUser(id).then(refresh)} />
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">用户管理</h2>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {openReset ? (
        <ResetPasswordModal
          open={openReset}
          setOpen={setOpenReset}
          id={userId}
        />
      ) : (
        <></>
      )}
      {createState ? (
        <CreateUserModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
        />
      ) : (
        <></>
      )}
      {updateState ? (
        <UpdateUserModal
          open={updateState}
          setOpen={setUpdateState}
          refresh={refresh}
          id={userId}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default UserFc;
