import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  CaptchaDto,
  CreateRoleDto,
  CreateUserDto,
  Login2Params,
  LoginParams,
  LoginResult,
  Register2Dto,
  RegisterDto,
  Role,
  UpdateRoleDto,
  UpdateUserDto,
  User,
} from './auth.interface';

export const LOGIN_URL = '/login';
export const LOGIN2_URL = '/login2';
export const REGISTER_URL = '/register';
export const REGISTER2_URL = '/register2';
export const CAPTCHA_CODE_URL = '/captcha';
export const LOGOUT_URL = '/logout';

export const login = async (parms: LoginParams) => {
  return getData(
    axios.post<LoginResult>(api('/login'), parms, {
      handlerErrorManually: true,
    })
  );
};

export const login2 = async (parms: Login2Params) => {
  return getData(
    axios.post<LoginResult>(api('/login2'), parms, {
      handlerErrorManually: true,
    })
  );
};

export const register = async (parms: RegisterDto) => {
  return getData(
    axios.post(api('/register'), parms, {
      handlerErrorManually: true,
    })
  );
};

export const register2 = async (parms: Register2Dto) => {
  return getData(
    axios.post(api('/register2'), parms, {
      handlerErrorManually: true,
    })
  );
};

export const captcha = async (parms: CaptchaDto) => {
  return getData(
    axios.post(api('/captcha'), parms, {
      handlerErrorManually: true,
    })
  );
};

export const logout = async () => {
  return getData(axios.get<void>(api('/logout')));
};

const apiUsers = (p = '') => {
  return api('/users', p);
};

// https://ahooks.js.org/zh-CN/hooks/use-antd-table
export const getUsers = async ({
  current,
  pageSize,
}: {
  current: number;
  pageSize: number;
  // sorter;
  // filters;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = apiUsers('?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<User>>(url));
  return { total: data.total, list: data.items };
};

export const getUser = async (id: number) => {
  return getData(axios.get<User>(apiUsers('/' + id)));
};

export const deleteUser = async (id: number) => {
  return getData(axios.delete<void>(apiUsers('/' + id)));
};

export const changePassword = async (
  id: number,
  value: { oldPassword: string; newPassword: string }
) => {
  return getData(
    axios.patch(apiUsers(`/${id}/changePassword`), value, {
      handlerErrorManually: true,
    })
  );
};

export const resetPasswordByAdmin = async (
  id: number,
  value: { password: string }
) => {
  return getData(
    axios.patch(apiUsers(`/${id}/resetPasswordByAdmin`), value, {
      handlerErrorManually: true,
    })
  );
};

export const createUser = async (value: CreateUserDto) => {
  return getData(axios.post(apiUsers(), value, { handlerErrorManually: true }));
};

export const updateUser = async (id: number, value: UpdateUserDto) => {
  return getData(
    axios.patch(apiUsers('/' + id), value, { handlerErrorManually: true })
  );
};

export const userRoles = async () => {
  return getData(axios.get<Role[]>(apiUsers(`/roles`)));
};

const apiRoles = (p = '') => {
  return api('/roles', p);
};
export const getRoles = async ({
  current,
  pageSize,
}: {
  current: number;
  pageSize: number;
  // sorter;
  // filters;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };

  const url = apiRoles('?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Role>>(url));
  return { total: data.total, list: data.items };
};

export const getRole = async (id: number) => {
  return getData(axios.get<Role>(apiRoles('/' + id)));
};

export const deleteRole = async (id: number) => {
  return getData(axios.delete<void>(apiRoles('/' + id)));
};

export const createRole = async (value: CreateRoleDto) => {
  return getData(axios.post(apiRoles(), value, { handlerErrorManually: true }));
};

export const updateRole = async (id: number, value: UpdateRoleDto) => {
  return getData(
    axios.patch(apiRoles('/' + id), value, { handlerErrorManually: true })
  );
};

export const getPermissions = async () => {
  return getData(axios.get<string[]>(apiRoles('/permissions')));
};
