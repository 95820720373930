import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  CreateProjectDto,
  Project,
  UpdateProjectDto,
} from './projects.interface';

export const getProjects = async ({ current, pageSize }: RequestParms) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api('/projects?' + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Project>>(url));
  return { total: data.total, list: data.items };
};

export const getProject = async (id: number) => {
  const url = api(`/projects/${id}`);
  return getData(axios.get<Project>(url));
};

export const getProjectsSelected = async () => {
  const url = api('/projects/all');
  return getData(axios.get<Project[]>(url));
};

export const deleteProject = async (id: number) => {
  const url = api(`/projects/${id}`);
  return getData(axios.delete<void>(url));
};

export const createProject = async (value: CreateProjectDto) => {
  const url = api(`/projects`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateProject = async (id: number, value: UpdateProjectDto) => {
  const url = api(`/projects/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
