import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Empty } from 'antd';
import { ProBreadcrumb } from 'pro';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateProjectModal from '../projects/components/Create';

const Start: React.FC = () => {
  const navigate = useNavigate();
  const [createState, setCreateState] = useState(false);
  const onSuccess = () => {
    navigate('/', { replace: true });
  };
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
      </div>
      <Card>
        <Empty>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setCreateState(true)}
          >
            新增项目
          </Button>
        </Empty>
      </Card>
      <CreateProjectModal
        open={createState}
        setOpen={setCreateState}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default Start;
