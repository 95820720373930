import { useRequest } from 'ahooks';
import { Form, Input, message, Modal, Select } from 'antd';
import { FormAlert, FormAlertProps, getErrorMessage } from 'pro';
import { Dispatch, SetStateAction, useState } from 'react';
import { CreateHoleDto } from '../holes.interface';
import { createHole } from '../holes.service';
const { TextArea } = Input;

const CreateHoleModal: React.FC<{
  projectId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
}> = ({ projectId, open, setOpen, onSuccess }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createHole, { manual: true });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: CreateHoleDto) => {
    try {
      setAlert({ display: false });
      await runAsync(projectId, value);
      message.success('新增成功!');
      onCancel();
      onSuccess();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="新增测孔"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <Form
        form={form}
        preserve={false}
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        initialValues={{ mode: '正反测量模式' }}
      >
        <FormAlert {...alert} />
        <Form.Item name="name" label="测孔名称" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="mode" label="测量模式" rules={[{ required: true }]}>
          <Select
            options={[
              { value: '正反测量模式', label: '正反测量模式' },
              { value: '单边测量模式', label: '单边测量模式' },
            ]}
          />
        </Form.Item>
        <Form.Item name="top" label="孔顶深度" rules={[{ required: true }]}>
          <Input addonAfter="m" />
        </Form.Item>
        <Form.Item
          name="interval"
          label="测量步长"
          rules={[{ required: true }]}
        >
          <Input addonAfter="m" />
        </Form.Item>
        <Form.Item name="bottom" label="孔底深度" rules={[{ required: true }]}>
          <Input addonAfter="m" />
        </Form.Item>
        <Form.Item name="note" label="备注">
          <TextArea rows={2} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateHoleModal;
