import { useRequest } from 'ahooks';
import { Form, Input, message, Modal, Select } from 'antd';
import { FormAlert, FormAlertProps, getErrorMessage, ProSpin } from 'pro';
import { Dispatch, SetStateAction, useState } from 'react';
import { UpdateHoleDto } from '../holes.interface';
import { getHole, updateHole } from '../holes.service';
const { TextArea } = Input;

const UpdateHoleModal: React.FC<{
  projectId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSuccess: () => void;
  id: number;
}> = ({ projectId, open, setOpen, onSuccess, id }) => {
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateHole, { manual: true });
  const { loading: getOneLoading } = useRequest(getHole, {
    defaultParams: [projectId, id],
    onSuccess: (v) => {
      form.setFieldsValue(v);
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
    setOpen(false);
  };
  const onFinish = async (value: UpdateHoleDto) => {
    try {
      setAlert({ display: false });

      await runAsync(projectId, id, value);
      message.success('修改成功!');
      onCancel();
      onSuccess();
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  return (
    <Modal
      title="编辑测孔"
      open={open}
      onOk={form.submit}
      onCancel={onCancel}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <ProSpin loading={getOneLoading}>
        <Form
          form={form}
          preserve={false}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 12 }}
        >
          <FormAlert {...alert} />
          <Form.Item name="name" label="测孔名称" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="mode" label="测量模式" rules={[{ required: true }]}>
            <Select
              options={[
                { value: '正反测量模式', label: '正反测量模式' },
                { value: '单边测量模式', label: '单边测量模式' },
              ]}
            />
          </Form.Item>
          <Form.Item name="top" label="孔顶深度" rules={[{ required: true }]}>
            <Input addonAfter="m" />
          </Form.Item>
          <Form.Item
            name="interval"
            label="测量步长"
            rules={[{ required: true }]}
          >
            <Input addonAfter="m" />
          </Form.Item>
          <Form.Item
            name="bottom"
            label="孔底深度"
            rules={[{ required: true }]}
          >
            <Input addonAfter="m" />
          </Form.Item>
          <Form.Item name="note" label="备注">
            <TextArea rows={2} />
          </Form.Item>
        </Form>
      </ProSpin>
    </Modal>
  );
};

export default UpdateHoleModal;
