import { Card, Spin } from 'antd';
import './index.css';
import { ReactNode } from 'react';

export const ProSpin: React.FC<{
  loading: boolean | boolean[];
  children?: ReactNode;
}> = ({ loading, children = <></> }) => {
  let b = false;
  if (Array.isArray(loading)) {
    for (const e of loading) {
      b = b || e;
    }
  } else {
    b = loading;
  }
  return (
    <>
      {b ? (
        <Card className="spin-wrap">
          <Spin />
        </Card>
      ) : (
        children
      )}
    </>
  );
};
