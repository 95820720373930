import { useAntdTable } from 'ahooks';
import { Space, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Role } from 'auth/auth.interface';
import { deleteRole, getRoles } from 'auth/auth.service';
import { permissions as allPermissions } from 'config/permissions';
import { ProBreadcrumb } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import CreateRoleModal from './Create';
import UpdateRoleModal from './Update';
import './index.css';

const RoleFc: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getRoles, {
    //refreshOnWindowFocus: true,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [roleId, setRoleId] = useState(0);

  const columns: ColumnsType<Role> = [
    {
      title: '角色名',
      dataIndex: 'name',
    },
    {
      title: '描述',
      dataIndex: 'desc',
    },
    {
      title: '权限',
      render: (_, { permissions }) => (
        <>
          {permissions &&
            permissions.map((p) => (
              <Tag key={p}>
                {allPermissions.find((per) => per.code === p)?.name}
              </Tag>
            ))}
        </>
      ),
    },
    {
      title: '操作',
      render: (_, { id, isDefault }) => (
        <Space size="middle">
          {!isDefault && (
            <>
              <UpdateButton
                onUpdate={() => {
                  setRoleId(id);
                  setUpdateState(true);
                }}
              />
              <DeleteButton onDel={() => deleteRole(id).then(refresh)} />
            </>
          )}
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">角色管理</h2>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      {createState && (
        <CreateRoleModal
          open={createState}
          setOpen={setCreateState}
          refresh={refresh}
        />
      )}
      {updateState && (
        <UpdateRoleModal
          open={updateState}
          setOpen={setUpdateState}
          refresh={refresh}
          id={roleId}
        />
      )}
    </>
  );
};

export default RoleFc;
