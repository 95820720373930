import { CloudUploadOutlined, PaperClipOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Avatar, Card, Col, Descriptions, Row, Tabs, Typography } from 'antd';
import Goback from 'pro/goBack/Goback';
import { ProBreadcrumb } from 'pro';
import { formatDate } from 'pro/table.pro';
import { QRCodeCanvas } from 'qrcode.react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getDevice } from '../devices.service';
import MaintainItemFc from '../../maintain-item';
const { Title } = Typography;

const DeviceDetail: React.FC = () => {
  const { deviceId } = useParams();
  if (!deviceId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(deviceId);
  const { data } = useRequest(getDevice, {
    defaultParams: [id],
    onSuccess: (v) => {},
  });
  // download QR code
  // https://stackblitz.com/edit/generate-download-qr-code-react?file=src%2FApp.js
  const downloadQRCode = () => {
    const qrCodeURL = (document.getElementById('qrCodeEl') as HTMLCanvasElement)
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let aEl = document.createElement('a');
    aEl.href = qrCodeURL;
    aEl.download = (data?.name ?? 'QR_Code') + '.png';
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };
  useEffect(() => {}, []);
  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">设备详情</h2>
          </div>
        </div>
      </div>
      <div>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Col>
            <Avatar
              shape="square"
              size={56}
              icon={<CloudUploadOutlined />}
              src={data?.avatar?.downloadUrl}
              alt="设备照片"
            />
          </Col>
          <Col style={{ padding: '0 0 0 24px' }}>
            {/* <Text type="secondary">{data && data.projectName}</Text> */}
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
              {data?.name}
            </div>
          </Col>
        </Row>
      </div>
      <div>
        <div className="content-tabs-wrap">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: `设备登记卡`,
                key: '1',
                children: (
                  <Card style={{ maxWidth: '880px', marginTop: '24px' }}>
                    <Row>
                      <Col span={24}>
                        <Title level={4} style={{ marginBottom: '16px' }}>
                          设备登记卡
                        </Title>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="gutter-row" span={24}>
                        {data?.avatar?.downloadUrl && (
                          <img
                            style={{ width: '60%' }}
                            src={data?.avatar?.downloadUrl}
                            alt="设备照片"
                          />
                        )}
                      </Col>
                      <Col span={16}>
                        <Descriptions>
                          <Descriptions.Item label="设备名称" span={3}>
                            {data?.name}
                          </Descriptions.Item>
                          <Descriptions.Item label="规格型号" span={3}>
                            {data?.model}
                          </Descriptions.Item>
                          <Descriptions.Item label="设备编号">
                            {data?.no}
                          </Descriptions.Item>
                          <Descriptions.Item label="制造日期" span={2}>
                            {formatDate(data?.madeAt)}
                          </Descriptions.Item>
                          <Descriptions.Item label="设备资料" span={3}>
                            {data?.summary}
                          </Descriptions.Item>
                          <Descriptions.Item label="附件">
                            <ul
                              style={{
                                listStyleType: 'none',
                                margin: 0,
                                padding: 0,
                              }}
                            >
                              {data?.attachments &&
                                data?.attachments.map((f) => (
                                  <li key={f.id}>
                                    <a href={f.downloadUrl}>
                                      <PaperClipOutlined />
                                      {f.originalname}
                                    </a>
                                  </li>
                                ))}
                            </ul>
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={8} style={{ paddingInlineStart: '16px' }}>
                        {data ? (
                          <a
                            href={data.name}
                            onClick={(e) => {
                              e.preventDefault();
                              downloadQRCode();
                            }}
                          >
                            <QRCodeCanvas
                              id="qrCodeEl"
                              size={240}
                              value={`设备名称: ${data.name}; 规格型号: ${
                                data.model
                              }; 设备编号: ${data.no}; 制造日期: ${formatDate(
                                data.madeAt
                              )}; 投运日期: ${formatDate(data.runningAt)};`}
                            />
                          </a>
                        ) : undefined}
                      </Col>
                    </Row>
                  </Card>
                ),
              },
              {
                label: `维修养护纪录`,
                key: '2',
                children: <MaintainItemFc deviceId={id} />,
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceDetail;
