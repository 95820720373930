import {
  ProjectOutlined,
  RobotOutlined,
  UserOutlined,
} from '@ant-design/icons';

export interface MenuDataType {
  path: string;
  name: string;
  icon?: React.ReactNode;
  permission?: string;
  children?: MenuDataType[];
}
// 当前菜单权限判断逻辑
// - 菜单项目必须指定对应权限
// - 默认用户除外
// todo
// - 根节点可继承父节点配置
// - 子节点匹配权限时父节点可省略权限配置
export const menuData: MenuDataType[] = [
  {
    path: 'dm',
    name: '设备管理',
    icon: <RobotOutlined />,
    children: [{ path: 'devices', name: '设备' }],
  },
  {
    path: 'measure',
    name: '测量管理',
    permission: 'project-*',
    icon: <ProjectOutlined />,
  },
  {
    path: 'sys',
    name: '系统设置',
    icon: <UserOutlined />,
    children: [
      { path: 'users', name: '用户', permission: 'user-*' },
      { path: 'roles', name: '角色', permission: 'role-*' },
    ],
  },
];
