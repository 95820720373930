import { Login, Register, RoleFc, UserFc } from 'auth';
import { DeviceCreate, DeviceDetail, DeviceList, DeviceUpdate } from 'devices';
import LayoutFc from 'layout';
import Start from 'projects/components/Start';
import HolesList from 'projects/holes/components/List';
import ProjectsList from 'projects/projects/components/List';
import CreateRecord from 'projects/records/components/Create';
import RecordsList from 'projects/records/components/List';
import { Navigate } from 'react-router-dom';

/*
  path 用来：路由、显示页面、导航菜单、导航状态、面包屑、标题、跳转、api、鉴权
  react-route 相关：路由、显示页面、导航菜单、导航状态、面包屑、标题、跳转
*/
export const pathObjects: PathObject[] = [
  { path: '/login', name: '登录', element: <Login /> },
  { path: '/register', name: '注册', element: <Register /> },
  {
    path: '/',
    name: '首页',
    element: <LayoutFc />,
    children: [
      { index: true, element: <Navigate to="measure" replace /> },
      // { path: 'dashboard', name: '概述', element: <Dashboard /> },
      {
        path: 'measure',
        name: '测量管理',
        children: [
          { index: true, element: <Navigate to="projects" replace /> },
          {
            path: 'projects/list',
            name: '项目管理',
            element: <ProjectsList />,
          },
          { path: 'projects/new', name: '项目管理', element: <Start /> },
          {
            path: 'projects',
            name: '测孔管理',
            element: <HolesList />,
          },
          {
            path: 'projects/:projectId',
            name: '测孔管理',
            children: [
              { index: true, element: <HolesList /> },
              {
                path: 'holes/:holeId/records',
                name: '监测数据',
                children: [
                  { index: true, element: <RecordsList /> },
                  { path: 'new', name: '新增记录', element: <CreateRecord /> },
                ],
              },
              {
                path: 'holes/:holeId/records/:recordId',
                name: '监测数据',
                element: <RecordsList />,
              },
            ],
          },
        ],
      },
      {
        path: 'sys',
        name: '系统设置',
        children: [
          { index: true, element: <Navigate to="users" replace /> },
          { path: 'users', name: '用户', element: <UserFc /> },
          { path: 'roles', name: '角色', element: <RoleFc /> },
        ],
      },
      {
        path: 'dm',
        name: '设备管理',
        children: [
          { index: true, element: <Navigate to="devices" replace /> },
          {
            path: 'devices',
            name: '设备',
            children: [
              { index: true, element: <DeviceList /> },
              { path: 'new', name: '添加设备', element: <DeviceCreate /> },
              {
                path: ':deviceId',
                name: '设备明细',
                element: <DeviceDetail />,
              },
              {
                path: ':deviceId/edit',
                name: '编辑设备',
                element: <DeviceUpdate />,
              },
            ],
          },
        ],
      },
    ],
  },
];
