import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './goback.css';
import { Space } from 'antd';

const Goback: React.FC<{ step?: number }> = ({ step = 1 }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const ids = [];
  let index = location.pathname.indexOf('/');
  while (index !== -1) {
    ids.push(index);
    index = location.pathname.indexOf('/', index + 1);
  }
  const path = location.pathname.substring(0, ids[ids.length - step]);

  return step === -1 ? (
    <div
      className="goback-btn"
      onClick={() => navigate(-1)}
      style={{ cursor: 'pointer' }}
    >
      <Space>
        <ArrowLeftOutlined />
        <span>返回</span>
      </Space>
    </div>
  ) : (
    <div className="goback-btn">
      <Link to={path}>
        <Space>
          <ArrowLeftOutlined />
          <span>返回</span>
        </Space>
      </Link>
    </div>
  );
};

export default Goback;
