// const permissions: string[] = [
//   'user-*',
//   'user-get',
//   'user-create',
//   'user-update',
//   'user-delete',
//   'role-*',
//   'role-get',
//   'role-create',
//   'role-update',
//   'role-delete',
// ];

export interface Permission {
  code: string;
  name: string;
}

export const permissions: Permission[] = [
  {
    code: 'device-*',
    name: '设备管理',
  },
  {
    code: 'project-*',
    name: '项目管理',
  },
  {
    code: 'user-*',
    name: '用户管理',
  },
  {
    code: 'role-*',
    name: '角色管理',
  },
];
