/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import type { PaginationProps } from 'antd';
import {
  Button,
  Card,
  Form,
  List,
  Modal,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
} from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
import { EmptyArrow, ProBreadcrumb, ProEmpty, ProSpin } from 'pro';
import { RefreshButton } from 'pro/table.pro';
import CreateProjectModal from 'projects/projects/components/Create';
import { Project } from 'projects/projects/projects.interface';
import { getProjectsSelected } from 'projects/projects/projects.service';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteHole, getHoles } from '../holes.service';
import CreateHoleModal from './Create';
import UpdateHoleModal from './Update';
import './index.css';
const { confirm } = Modal;

const HolesList: React.FC = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [projects, setProjects] = useState<Project[]>();
  const [l1, setL1] = useState(true);
  useEffect(() => {
    setL1(true);
    getProjectsSelected().then((ps) => {
      setL1(false);
      if (ps.length === 0) {
        navigate('/measure/projects/new', { replace: true });
      } else {
        setProjects(ps);
        let pid: number;
        if (projectId && ps.some((p) => Number(p.id) === Number(projectId))) {
          pid = Number(projectId);
        } else {
          pid = ps[0].id;
        }
        navigate(`/measure/projects/${pid}`, { replace: true });
        form.setFieldValue('projectId', pid.toString());
        run({ projectId: pid, current: 1, pageSize: 8 });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, data, tableProps, refresh, run } = useAntdTable(getHoles, {
    defaultPageSize: 8,
    manual: true,
  });

  const [form] = Form.useForm();
  const onValuesChange = async ({ projectId: pid }: { projectId: number }) => {
    navigate(`/measure/projects/${pid}`, { replace: true });
    form.setFieldValue('projectId', pid);
    run({ projectId: pid, current: 1, pageSize: 8 });
  };

  const onChange: PaginationProps['onChange'] = (page) => {
    run({
      projectId: form.getFieldValue('projectId'),
      current: page,
      pageSize: 8,
    });
  };

  // 新建项目的回调
  const [createState, setCreateState] = useState(false);
  const onSuccess = async (value: Project) => {
    setL1(true);
    const ps = await getProjectsSelected();
    setL1(false);
    setProjects(ps);
    navigate(`/measure/projects/${value.id}`, { replace: true });
    form.setFieldValue('projectId', value.id.toString());
    run({ projectId: value.id, current: 1, pageSize: 8 });
  };

  const [createHoleState, setCreateHoleState] = useState(false);
  const [updateHoleState, setUpdateHoleState] = useState(false);
  const [holeId, setHoleId] = useState(0);
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">
            <Skeleton
              className="app-skeleton"
              loading={l1 || loading}
              active
              paragraph={false}
            >
              {
                projects?.find((p) => p.id === form.getFieldValue('projectId'))
                  ?.name
              }
              测孔一览
            </Skeleton>
          </h2>
        </div>
      </div>
      <ProSpin loading={[l1, loading]}>
        <div className="content-toolbar-container">
          <Form
            form={form}
            layout="inline"
            initialValues={{
              projectId: projects && projects[0].id,
            }}
            onValuesChange={onValuesChange}
          >
            <Form.Item name="projectId">
              <Select
                placeholder="选择项目"
                popupMatchSelectWidth={false}
                options={projects?.map((e) => ({
                  value: e.id,
                  label: e.name,
                }))}
              />
            </Form.Item>
            <Space className="porject-setting-warp">
              <Tooltip title="新建项目">
                <PlusOutlined
                  className="project-toolbar-container"
                  onClick={() => setCreateState(true)}
                />
              </Tooltip>
              <Tooltip title="管理项目">
                <SettingOutlined
                  className="project-toolbar-container"
                  onClick={() => navigate('/measure/projects/list')}
                />
              </Tooltip>
            </Space>
          </Form>
          <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setCreateHoleState(true)}
            >
              新增测孔
            </Button>
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>

        <ProEmpty data={data?.list}>
          <div>
            <List
              pagination={{
                ...tableProps.pagination,
                showTotal: (t) => `共 ${t} 条`,
                onChange,
              }}
              grid={{
                gutter: 16,
                column: 6,
              }}
              dataSource={data?.list}
              renderItem={({
                id,
                name,
                mode,
                top,
                interval,
                bottom,
                note,
                records,
              }) => (
                <List.Item>
                  <Card
                    hoverable
                    className="hole-card-warp"
                    style={{ width: '220px' }}
                    title={name}
                    extra={<Tag color="blue">{mode}</Tag>}
                    actions={[
                      <a
                        key="edit"
                        href="/"
                        onClick={async (e) => {
                          e.preventDefault();
                          setHoleId(id);
                          setUpdateHoleState(true);
                        }}
                      >
                        <EditOutlined />
                      </a>,
                      <a
                        key="del"
                        href="/"
                        onClick={async (e) => {
                          e.preventDefault();
                          confirm({
                            title: '你想要删除此项内容吗!',
                            icon: <ExclamationCircleOutlined />,
                            okText: '删除',
                            okType: 'danger' as LegacyButtonType,
                            onOk: () =>
                              deleteHole(
                                form.getFieldValue('projectId'),
                                id
                              ).then(refresh),
                            onCancel() {},
                          });
                        }}
                      >
                        <DeleteOutlined />
                      </a>,
                    ]}
                  >
                    <div
                      onClick={() =>
                        navigate(
                          `/measure/projects/${form.getFieldValue(
                            'projectId'
                          )}/holes/${id}/records`
                        )
                      }
                    >
                      <div className="hole-card-title">
                        <span>今年已测次数 / 总次数</span>
                      </div>
                      <div className="hole-card-content">
                        {
                          records.filter(
                            (i) =>
                              new Date(i.createAt) >=
                              new Date(new Date().getFullYear(), 0, 1)
                          ).length
                        }
                        <span> / {records.length}</span>
                      </div>
                      <div className="hole-card-title">
                        <span>孔顶 / 孔底深度 / 步长</span>
                      </div>
                      <div className="hole-card-content2">
                        <Space size="small">
                          <Tag>{top}</Tag>
                          <span>/</span>
                          <Tag>{bottom}</Tag>
                          <span>/</span>
                          <Tag>{interval}</Tag>
                          <span className="hole-card-content2-span">(m)</span>
                        </Space>
                      </div>
                      {note && (
                        <>
                          <div className="hole-card-title">
                            <span>备注</span>
                          </div>
                          <div className="hole-card-content2">{note}</div>
                        </>
                      )}
                    </div>
                  </Card>
                </List.Item>
              )}
            />
          </div>
        </ProEmpty>
      </ProSpin>
      <EmptyArrow open={createState}>
        <CreateProjectModal
          open={createState}
          setOpen={setCreateState}
          onSuccess={onSuccess}
        />
      </EmptyArrow>
      {createHoleState && (
        <CreateHoleModal
          projectId={form.getFieldValue('projectId')}
          open={createHoleState}
          setOpen={setCreateHoleState}
          onSuccess={refresh}
        />
      )}

      {updateHoleState && (
        <UpdateHoleModal
          projectId={form.getFieldValue('projectId')}
          open={updateHoleState}
          setOpen={setUpdateHoleState}
          onSuccess={refresh}
          id={holeId}
        />
      )}
    </>
  );
};

export default HolesList;
