import { menuData, MenuDataType } from 'config/menu.data';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const getItems: (items: MenuDataType[]) => MenuDataType[] = (items) => {
  let a: MenuDataType[] = [];
  for (const i of items) {
    a.push(i);
    if (i.children) {
      a = a.concat(getItems(i.children));
    }
  }
  return a;
};
const items = getItems(menuData).concat([
  {
    name: '登录',
    path: 'login',
  },
  {
    name: '注册',
    path: 'register',
  },
  {
    name: '综合监测',
    path: '',
  },
]);

export const setPageTitle = (pathname: string) => {
  const s = pathname.split('/').filter((e) => e);
  const last = s.pop();
  const name = items.find((i) => i.path === last)?.name;
  document.title = `${name ?? '综合监测'} - 星链感知`;
};

/**
 * update page title by url pathname
 */
export function useTitle() {
  const location = useLocation();
  useEffect(() => {
    setPageTitle(location.pathname);
  });
}
