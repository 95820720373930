import { useAntdTable } from 'ahooks';
import { Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Goback, ProBreadcrumb } from 'pro';
import {
  CreateButton,
  DeleteButton,
  RefreshButton,
  UpdateButton,
  extraProps,
} from 'pro/table.pro';
import { useState } from 'react';
import { Project } from '../projects.interface';
import { deleteProject, getProjects } from '../projects.service';
import CreateProjectModal from './Create';
import UpdateProjectModal from './Update';

const ProjectsList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getProjects, {
    //refreshOnWindowFocus: true,
  });
  const [createState, setCreateState] = useState(false);
  const [updateState, setUpdateState] = useState(false);
  const [projectId, setProjectId] = useState(0);

  const columns: ColumnsType<Project> = [
    {
      title: '项目名称',
      dataIndex: 'name',
    },
    {
      title: '备注',
      dataIndex: 'note',
    },
    {
      title: '操作',
      render: (_, { id }) => (
        <Space size="middle">
          <UpdateButton
            onUpdate={() => {
              setProjectId(id);
              setUpdateState(true);
            }}
          />
          <DeleteButton onDel={() => deleteProject(id).then(refresh)} />
        </Space>
      ),
    },
  ];
  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={-1} />
            <h2 className="content-toolbar-title">项目管理</h2>
          </div>
          <Space>
            <CreateButton onCreate={() => setCreateState(true)} />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
      <CreateProjectModal
        open={createState}
        setOpen={setCreateState}
        onSuccess={refresh}
      />
      {updateState && (
        <UpdateProjectModal
          open={updateState}
          setOpen={setUpdateState}
          onSuccess={refresh}
          id={projectId}
        />
      )}
    </>
  );
};

export default ProjectsList;
