import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import { CreateHoleDto, Hole, UpdateHoleDto } from './holes.interface';

export const getHoles = async ({
  projectId,
  current,
  pageSize,
}: RequestParms & {
  projectId: number;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api(`/projects/${projectId}/holes?` + qs.stringify(requestParms));
  const data = await getData(axios.get<PaginationResult<Hole>>(url));
  return { total: data.total, list: data.items };
};

export const getHole = async (projectId: number, id: number) => {
  const url = api(`/projects/${projectId}/holes/${id}`);
  return getData(axios.get<Hole>(url));
};

export const deleteHole = async (projectId: number, id: number) => {
  const url = api(`/projects/${projectId}/holes/${id}`);
  return getData(axios.delete<void>(url));
};

export const createHole = async (projectId: number, value: CreateHoleDto) => {
  const url = api(`/projects/${projectId}/holes`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateHole = async (
  projectId: number,
  id: number,
  value: UpdateHoleDto
) => {
  const url = api(`/projects/${projectId}/holes/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
