import axios, { getData } from 'common/axios.pro';
import { apiPrefix as api } from 'common/url.help';
import qs from 'qs';
import {
  CreateRecordDto,
  Record,
  RecordDto,
  UpdateRecordDto,
} from './records.interface';

export const getRecords = async ({
  projectId,
  holeId,
  current,
  pageSize,
}: RequestParms & {
  projectId: string | undefined;
  holeId: string | undefined;
}) => {
  const requestParms = {
    current: current,
    size: pageSize,
  };
  const url = api(
    `/projects/${projectId}/holes/${holeId}/records?` +
      qs.stringify(requestParms)
  );
  const data = await getData(axios.get<PaginationResult<Record>>(url));
  return { total: data.total, list: data.items };
};

export const getRecord = async (
  projectId: string | undefined,
  holeId: string | undefined,
  id: number
) => {
  const url = api(`/projects/${projectId}/holes/${holeId}/records/${id}`);
  return getData(axios.get<Record>(url));
};

export const getLastRecord = async (
  projectId: string | undefined,
  holeId: string | undefined,
  id: number
) => {
  const url = api(`/projects/${projectId}/holes/${holeId}/records/${id}/last`);
  return getData(axios.get<Record>(url));
};

// 获取本次监测报告
export const getReport = async (
  projectId: string | undefined,
  holeId: string | undefined,
  id: number
) => {
  const url = api(
    `/projects/${projectId}/holes/${holeId}/records/${id}/report`
  );
  return getData(axios.get<RecordDto>(url));
};

export const getRecordsSelected = async (
  projectId: string | undefined,
  holeId: string | undefined
) => {
  const url = api(`/projects/${projectId}/holes/${holeId}/records/all`);
  return getData(axios.get<Record[]>(url));
};

export const deleteRecord = async (
  projectId: string | undefined,
  holeId: string | undefined,
  id: number
) => {
  const url = api(`/projects/${projectId}/holes/${holeId}/records/${id}`);
  return getData(axios.delete<void>(url));
};

export const createRecord = async (
  projectId: string | undefined,
  holeId: string | undefined,
  value: CreateRecordDto
) => {
  const url = api(`/projects/${projectId}/holes/${holeId}/records`);
  return getData(axios.post(url, value, { handlerErrorManually: true }));
};

export const updateRecord = async (
  projectId: string | undefined,
  holeId: string | undefined,
  id: number,
  value: UpdateRecordDto
) => {
  const url = api(`/projects/${projectId}/holes/${holeId}/records/${id}`);
  return getData(axios.patch(url, value, { handlerErrorManually: true }));
};
