import { ConfigProvider } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import { AuthProvider } from 'auth/AuthProvider';
import { router } from 'config/router';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import './config/dayjs.config';

createRoot(document.getElementById('root') as HTMLElement).render(
  <ConfigProvider
    locale={zhCN}
    theme={{
      token: {
        // colorFillQuaternary: 'rgba(0, 0, 0, 0.00)',
      },
      components: {
        Menu: {
          /* here is your component tokens */
          itemSelectedBg: '#EAEAEA',
          itemActiveBg: '#EDEDED',
        },
      },
    }}
  >
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </ConfigProvider>
);
