import {
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import { LegacyButtonType } from 'antd/es/button/button';
import { ColumnsType } from 'antd/es/table';
import { dayjsfmt } from 'common/dayjsfmt';
import csvDownload from 'json-to-csv-export';
import { Goback, ProBreadcrumb, ProSpin } from 'pro';
import { RefreshButton } from 'pro/table.pro';
import { getHole } from 'projects/holes/holes.service';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Record, RecordItemDto } from '../records.interface';
import {
  deleteRecord,
  getLastRecord,
  getRecord,
  getRecordsSelected,
  getReport,
} from '../records.service';
import ReportChart from './ReportChart';
import './index.css';
const { confirm } = Modal;

const RecordsList: React.FC = () => {
  // 路由
  const navigate = useNavigate();
  const { projectId, holeId, recordId } = useParams();

  // 数据表格
  const [tableProps, setTableProps] = useState<{
    dataSource?: RecordItemDto[];
    columns?: ColumnsType<RecordItemDto>;
  }>();

  // 监测记录
  const [records, setRecords] = useState<Record[]>();
  // 上次监测记录
  const [lastRecord, setLastRecord] = useState<Record | undefined>();

  // 测孔标签
  const { data: hole, loading: lh } = useRequest(getHole, {
    defaultParams: [Number(projectId), Number(holeId)],
  });

  const { loading: lr, runAsync: grs } = useRequest(getRecordsSelected, {
    manual: true,
  });

  // 初始化
  useEffect(() => {
    grs(projectId, holeId).then((rds) => {
      if (rds.length === 0) {
        navigate(`/measure/projects/${projectId}/holes/${holeId}/records/new`);
      } else {
        setRecords(rds);
        let rid: number;
        if (recordId && rds.some((e) => Number(e.id) === Number(recordId))) {
          rid = Number(recordId);
        } else {
          rid = rds[0].id;
        }
        navigate(
          `/measure/projects/${projectId}/holes/${holeId}/records/${rid}`,
          { replace: true }
        );

        form.setFieldValue('recordId', rid.toString());
        run(projectId, holeId, rid);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // 删除按钮
  const onDelete = async () => {
    const rds = await grs(projectId, holeId);
    if (rds.length === 0) {
      navigate(`/measure/projects/${projectId}/holes/${holeId}/records/new`);
    } else {
      setRecords(rds);
      let rid: number;
      if (recordId && rds.some((e) => Number(e.id) === Number(recordId))) {
        rid = Number(recordId);
      } else {
        rid = rds[0].id;
      }
      navigate(
        `/measure/projects/${projectId}/holes/${holeId}/records/${rid}`,
        { replace: true }
      );

      form.setFieldValue('recordId', rid.toString());
      run(projectId, holeId, rid);
    }
  };

  // 上次监测数据
  const { loading: llo, runAsync: findlastRecord } = useRequest(getLastRecord, {
    manual: true,
  });

  const { loading: lrp, runAsync: runRep } = useRequest(getReport, {
    manual: true,
  });

  const isZeroOrNotNull = (val: number | undefined) => {
    if (val === 0) {
      return true;
    } else {
      return val;
    }
  };

  // 加载本次监测数据
  const { loading, run, refresh } = useRequest(getRecord, {
    manual: true,
    onSuccess: async (thisOne) => {
      const lastOne = await findlastRecord(projectId, holeId, thisOne.id);
      const report = await runRep(projectId, holeId, thisOne.id);
      setLastRecord(lastOne);

      // 是否 双轴
      const isDouble = thisOne.recordItems.some((e) => isZeroOrNotNull(e.b));
      console.log('report', report);
      if (!isDouble) {
        // 单轴设备
        if (thisOne.hole.mode !== '正反测量模式') {
          // 单边测量模式
          if (!isZeroOrNotNull(report.recordItems[0].ac)) {
            // 只做了一次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a }) => a.toFixed(3),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({ dataSource: report.recordItems, columns: cols });
          } else if (!isZeroOrNotNull(report.recordItems[0].laac)) {
            // 做了两次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a }) => a.toFixed(3),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({ dataSource: report.recordItems, columns: cols });
          } else {
            // 做了两次以上监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a }) => a.toFixed(3),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
                render: ({ laac }) => laac.toFixed(3),
              },
            ];
            setTableProps({ dataSource: report.recordItems, columns: cols });
          }
        } else {
          // 正反测量模式
          if (!isZeroOrNotNull(report.recordItems[0].ac)) {
            // 只做了一次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a, a1, a2 }) => (
                  <Tooltip
                    title={`${a1.toFixed(3)} - ${
                      a2 > 0 ? a2.toFixed(3) : `(${a2.toFixed(3)})`
                    }`}
                  >
                    {a.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({
              dataSource: report.recordItems,
              columns: cols,
            });
          } else if (!isZeroOrNotNull(report.recordItems[0].laac)) {
            // 做了两次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a, a1, a2 }) => (
                  <Tooltip
                    title={`${a1.toFixed(3)} - ${
                      a2 > 0 ? a2.toFixed(3) : `(${a2.toFixed(3)})`
                    }`}
                  >
                    {a.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({
              dataSource: report.recordItems,
              columns: cols,
            });
          } else {
            // 做了两次以上监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a, a1, a2 }) => (
                  <Tooltip
                    title={`${a1.toFixed(3)} - ${
                      a2 > 0 ? a2.toFixed(3) : `(${a2.toFixed(3)})`
                    }`}
                  >
                    {a.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
                render: ({ laac }) => laac.toFixed(3),
              },
            ];
            setTableProps({
              dataSource: report.recordItems,
              columns: cols,
            });
          }
        }
      } else {
        // 双轴设备
        if (thisOne.hole.mode !== '正反测量模式') {
          // 单边测量模式
          if (!isZeroOrNotNull(report.recordItems[0].ac)) {
            // 只做了一次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a }) => a.toFixed(3),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴本次测量值(mm)',
                align: 'center',
                render: ({ b }) => b.toFixed(3),
              },
              {
                title: 'B轴本次变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴本次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({ dataSource: report.recordItems, columns: cols });
          } else if (!isZeroOrNotNull(report.recordItems[0].laac)) {
            // 做了两次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a }) => a.toFixed(3),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴本次测量值(mm)',
                align: 'center',
                render: ({ b }) => b.toFixed(3),
              },
              {
                title: 'B轴本次变化量(mm)',
                align: 'center',
                render: ({ bc }) => bc.toFixed(3),
              },
              {
                title: 'B轴本次累计变化量(mm)',
                align: 'center',
                render: ({ abc }) => abc.toFixed(3),
              },
              {
                title: 'B轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({ dataSource: report.recordItems, columns: cols });
          } else {
            // 做了两次以上监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a }) => a.toFixed(3),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
                render: ({ laac }) => laac.toFixed(3),
              },
              {
                title: 'B轴本次测量值(mm)',
                align: 'center',
                render: ({ b }) => b.toFixed(3),
              },
              {
                title: 'B轴本次变化量(mm)',
                align: 'center',
                render: ({ bc }) => bc.toFixed(3),
              },
              {
                title: 'B轴本次累计变化量(mm)',
                align: 'center',
                render: ({ abc }) => abc.toFixed(3),
              },
              {
                title: 'B轴上次累计变化量(mm)',
                align: 'center',
                render: ({ labc }) => labc.toFixed(3),
              },
            ];
            setTableProps({ dataSource: report.recordItems, columns: cols });
          }
        } else {
          // 正反测量模式
          if (!isZeroOrNotNull(report.recordItems[0].ac)) {
            // 只做了一次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a, a1, a2 }) => (
                  <Tooltip
                    title={`${a1.toFixed(3)} - ${
                      a2 > 0 ? a2.toFixed(3) : `(${a2.toFixed(3)})`
                    }`}
                  >
                    {a.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴本次测量值(mm)',
                align: 'center',
                render: ({ b, b1, b2 }) => (
                  <Tooltip
                    title={`${b1.toFixed(3)} - ${
                      b2 > 0 ? b2.toFixed(3) : `(${b2.toFixed(3)})`
                    }`}
                  >
                    {b.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'B轴本次变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴本次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({
              dataSource: report.recordItems,
              columns: cols,
            });
          } else if (!isZeroOrNotNull(report.recordItems[0].laac)) {
            // 做了两次监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a, a1, a2 }) => (
                  <Tooltip
                    title={`${a1.toFixed(3)} - ${
                      a2 > 0 ? a2.toFixed(3) : `(${a2.toFixed(3)})`
                    }`}
                  >
                    {a.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
              },
              {
                title: 'B轴本次测量值(mm)',
                align: 'center',
                render: ({ b, b1, b2 }) => (
                  <Tooltip
                    title={`${b1.toFixed(3)} - ${
                      b2 > 0 ? b2.toFixed(3) : `(${b2.toFixed(3)})`
                    }`}
                  >
                    {b.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'B轴本次变化量(mm)',
                align: 'center',
                render: ({ bc }) => bc.toFixed(3),
              },
              {
                title: 'B轴本次累计变化量(mm)',
                align: 'center',
                render: ({ abc }) => abc.toFixed(3),
              },
              {
                title: 'B轴上次累计变化量(mm)',
                align: 'center',
              },
            ];
            setTableProps({
              dataSource: report.recordItems,
              columns: cols,
            });
          } else {
            // 做了两次以上监测
            const cols: ColumnsType<RecordItemDto> = [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴本次测量值(mm)',
                align: 'center',
                render: ({ a, a1, a2 }) => (
                  <Tooltip
                    title={`${a1.toFixed(3)} - ${
                      a2 > 0 ? a2.toFixed(3) : `(${a2.toFixed(3)})`
                    }`}
                  >
                    {a.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'A轴本次变化量(mm)',
                align: 'center',
                render: ({ ac }) => ac.toFixed(3),
              },
              {
                title: 'A轴本次累计变化量(mm)',
                align: 'center',
                render: ({ aac }) => aac.toFixed(3),
              },
              {
                title: 'A轴上次累计变化量(mm)',
                align: 'center',
                render: ({ laac }) => laac.toFixed(3),
              },
              {
                title: 'B轴本次测量值(mm)',
                align: 'center',
                render: ({ b, b1, b2 }) => (
                  <Tooltip
                    title={`${b1.toFixed(3)} - ${
                      b2 > 0 ? b2.toFixed(3) : `(${b2.toFixed(3)})`
                    }`}
                  >
                    {b.toFixed(3)}
                  </Tooltip>
                ),
              },
              {
                title: 'B轴本次变化量(mm)',
                align: 'center',
                render: ({ bc }) => bc.toFixed(3),
              },
              {
                title: 'B轴本次累计变化量(mm)',
                align: 'center',
                render: ({ abc }) => abc.toFixed(3),
              },
              {
                title: 'B轴上次累计变化量(mm)',
                align: 'center',
                render: ({ labc }) => labc.toFixed(3),
              },
            ];
            setTableProps({
              dataSource: report.recordItems,
              columns: cols,
            });
          }
        }
      }
    },
  });

  // 测孔记录表单
  const [form] = Form.useForm();
  const onValuesChange = async ({ recordId: rid }: { recordId: number }) => {
    navigate(`/measure/projects/${projectId}/holes/${holeId}/records/${rid}`, {
      replace: true,
    });
    form.setFieldValue('recordId', rid);
    run(projectId, holeId, rid);
  };

  const headerMeta = [
    ['depth', '深度(m)'],
    ['a1', 'A轴本次测量原始值(mm)'],
    ['b1', 'B轴本次测量原始值(mm)'],
    ['a2', 'A轴本次反测原始值(mm)'],
    ['b2', 'B轴本次反测原始值(mm)'],
    ['a', 'A轴本次测量值(mm)'],
    ['b', 'B轴本次测量值(mm)'],
    ['ac', 'A轴本次变化量(mm)'],
    ['bc', 'B轴本次变化量(mm)'],
    ['aac', 'A轴本次累计变化量(mm)'],
    ['abc', 'B轴本次累计变化量(mm)'],
    ['laac', 'A轴上次累计变化量(mm)'],
    ['labc', 'B轴上次累计变化量(mm)'],
  ];

  const getHeaders = () => {
    if (tableProps?.dataSource) {
      const item = tableProps.dataSource[0];
      const hs = [];
      for (const p in item) {
        const meta = headerMeta.find((m) => m[0] === p);
        if (meta) {
          hs.push(meta[1]);
          continue;
        }
      }
      return hs;
    }
  };

  // 下载
  const download = () => {
    csvDownload({
      data: tableProps?.dataSource as [],
      filename: `${hole?.project.name}_${hole?.name}_${
        records?.find((r) => r.id === form.getFieldValue('recordId'))?.createAt
      }_监测数据`,
      delimiter: ',',
      headers: getHeaders(),
    });
  };

  // 反转数组
  const toReversed = (arr?: RecordItemDto[]) => {
    if (arr) {
      const newarr = [] as RecordItemDto[];
      for (let i = 0; i < arr.length; i++) {
        newarr.push(arr[arr.length - 1 - i]);
      }
      return newarr;
    }
  };

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={4} />
            <Skeleton
              className="app-skeleton"
              loading={lh || loading || lr || llo || lrp}
              active
              paragraph={false}
              style={{ width: 400 }}
            >
              <h2 className="content-toolbar-title">
                {`${hole?.project?.name}${hole?.name}监测数据`}
              </h2>
              <Space
                size="small"
                style={{ fontWeight: 'normal', marginLeft: '24px' }}
              >
                <Tag color="blue">{hole?.mode}</Tag>
                <Tag color="blue">顶深 {hole?.top} m</Tag>
                <Tag color="blue">底深 {hole?.bottom} m</Tag>
                <Tag color="blue">步长 {hole?.interval} m</Tag>
              </Space>
            </Skeleton>
          </div>
        </div>
      </div>
      <Row gutter={[16, 24]}>
        <Col span={24}>
          <ProSpin loading={false}>
            <Card>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space size="small">
                  <Form
                    form={form}
                    layout="inline"
                    initialValues={{
                      recordId: records && records[0].id,
                    }}
                    onValuesChange={onValuesChange}
                  >
                    <Form.Item name="recordId" label="本次监测时间">
                      <Select
                        placeholder="选择记录"
                        popupMatchSelectWidth={false}
                        options={records?.map((e) => ({
                          value: e.id,
                          label: dayjsfmt(e.createAt),
                        }))}
                      />
                    </Form.Item>
                  </Form>
                  <div>
                    <Skeleton
                      className="app-skeleton"
                      loading={lh || loading || lr || llo || lrp}
                      active
                      paragraph={false}
                      style={{ width: 200 }}
                    >
                      上次监测时间：
                      {lastRecord ? dayjsfmt(lastRecord.createAt) : '无记录'}
                    </Skeleton>
                  </div>
                </Space>
                <Space>
                  <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      navigate(
                        `/measure/projects/${projectId}/holes/${holeId}/records/new`
                      )
                    }
                  >
                    新增记录
                  </Button>
                  <Tooltip title="下载数据">
                    <Button icon={<DownloadOutlined />} onClick={download} />
                  </Tooltip>
                  <Tooltip title="删除记录">
                    <Button
                      danger
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={async (e) => {
                        confirm({
                          title: '将会删除记录下所有数据',
                          icon: <ExclamationCircleOutlined />,
                          okText: '删除',
                          okType: 'danger' as LegacyButtonType,
                          onOk: () =>
                            deleteRecord(
                              projectId,
                              holeId,
                              form.getFieldValue('recordId')
                            ).then(onDelete),
                          onCancel() {},
                        });
                      }}
                    />
                  </Tooltip>

                  <RefreshButton onRefresh={refresh} />
                </Space>
              </div>
            </Card>
          </ProSpin>
        </Col>
        <Col span={24}>
          <Card>
            <Row>
              <Col span={12}>
                <Table
                  id="record-table-datasource"
                  columns={tableProps?.columns}
                  dataSource={tableProps?.dataSource}
                  size="small"
                  loading={loading || llo || lrp}
                  pagination={false}
                  rowKey="depth"
                  scroll={{ y: 540 }}
                />
              </Col>
              <Col span={12}>
                {tableProps?.dataSource && (
                  <ReportChart
                    reportItems={toReversed(tableProps?.dataSource)}
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default RecordsList;
