/* eslint-disable @typescript-eslint/no-explicit-any */
import { InboxOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Empty,
  Form,
  Row,
  Upload,
  message,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { normFile } from 'files/files.service';
import {
  FormAlert,
  FormAlertProps,
  Goback,
  ProBreadcrumb,
  getErrorMessage,
} from 'pro';
import { getHole } from 'projects/holes/holes.service';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CreateRecordDto,
  CreateRecordItemDto,
  CsvRecordItem,
} from '../records.interface';
import { createRecord } from '../records.service';

// 值不能是空，但是能被转为数值
const valueNotEmptyCanToNum = (val: any) => {
  return val !== '' && isFinite(val);
};

const CreateRecord: React.FC = () => {
  const navigate = useNavigate();
  const { projectId, holeId } = useParams();
  const { data: hole } = useRequest(getHole, {
    defaultParams: [Number(projectId), Number(holeId)],
  });
  const [form] = Form.useForm();
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(createRecord, { manual: true });

  const [dataSource, setDataSource] = useState<CsvRecordItem[]>();
  const [cols, setCols] = useState<ColumnsType<CsvRecordItem>>();

  const onFinish = async (value: CreateRecordDto) => {
    try {
      setAlert({ display: false });
      if (!dataSource || dataSource.length === 0) {
        message.error('上传数据有误');
      } else {
        const items = [] as CreateRecordItemDto[];
        for (const item of dataSource) {
          items.push({ angle: 0, depth: item.depth, a: item.a1, b: item.b1 });
          if (item.a2 !== undefined) {
            items.push({
              angle: 180,
              depth: item.depth,
              a: item.a2,
              b: item.b2,
            });
          }
        }
        value.recordItems = items;
        const record = await runAsync(projectId, holeId, value);
        message.success('新增成功!');
        navigate(
          `/measure/projects/${projectId}/holes/${holeId}/records/${record.id}`
        );
      }
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  // 预览上传文件
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const beforeUpload = (file: any) => {
    processFile(file);
    return Upload.LIST_IGNORE;
  };

  function processFile(file: any) {
    var reader = new FileReader();
    reader.onload = function (e) {
      var texto = e.target?.result;
      const items = csvJSON(texto);
      setDataSource(items);
      setCols(
        hole?.mode === '正反测量模式'
          ? [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: '正向A轴测值(mm)',
                dataIndex: 'a1',
                align: 'center',
              },
              {
                title: '正向B轴测值(mm)',
                dataIndex: 'b1',
                align: 'center',
              },
              {
                title: '反向A轴测值(mm)',
                dataIndex: 'a2',
                align: 'center',
              },
              {
                title: '反向B轴测值(mm)',
                dataIndex: 'b2',
                align: 'center',
              },
            ]
          : [
              {
                title: '深度(m)',
                dataIndex: 'depth',
                align: 'center',
              },
              {
                title: 'A轴测值(mm)',
                dataIndex: 'a1',
                align: 'center',
              },
              {
                title: 'B轴测值(mm)',
                dataIndex: 'b1',
                align: 'center',
              },
            ]
      );
    };
    reader.readAsText(file);
  }

  // 4 种情况 正反双轴 正反单轴 单边双轴 单边单轴
  function csvJSON(csv: any) {
    var lines = csv.split('\n').filter((t: any) => t);
    var result = [];
    var headers = lines[0].replace('\r', '').split(',');
    if (hole?.mode === '正反测量模式') {
      // 格式检查
      if (
        headers[0] !== '深度(m)' ||
        headers[1] !== '正向A轴测值(mm)' ||
        headers[2] !== '正向B轴测值(mm)' ||
        headers[3] !== '反向A轴测值(mm)' ||
        headers[4] !== '反向B轴测值(mm)'
      ) {
        message.error('上传文件有误');
        throw new Error('上传文件有误');
      }
      // 第三、五列包含任意数据，即为双轴
      let isDouble = false;
      for (let i = 1; i < lines.length; i++) {
        const currentline = lines[i].replace('\r', '').split(',');
        if (
          valueNotEmptyCanToNum(currentline[2]) ||
          valueNotEmptyCanToNum(currentline[4])
        ) {
          isDouble = true;
          break;
        }
      }

      for (let i = 1; i < lines.length; i++) {
        const obj = {} as CsvRecordItem;
        const currentline = lines[i].replace('\r', '').split(',');
        if (!currentline[0]) continue;
        obj.depth = Number(currentline[0]);
        obj.a1 = Number(currentline[1]);
        if (isDouble) obj.b1 = Number(currentline[2]);
        obj.a2 = Number(currentline[3]);
        if (isDouble) obj.b2 = Number(currentline[4]);
        result.push(obj);
      }

      result.sort((a, b) => a.depth - b.depth);
      return result;
    } else {
      // 单边测量模式
      // 格式检查
      if (
        headers[0] !== '深度(m)' ||
        headers[1] !== 'A轴测值(mm)' ||
        headers[2] !== 'B轴测值(mm)'
      ) {
        message.error('上传文件有误');
        throw new Error('上传文件有误');
      }
      // 第三列包含任意数据，即为双轴
      let isDouble = false;
      for (let i = 1; i < lines.length; i++) {
        const currentline = lines[i].replace('\r', '').split(',');
        if (valueNotEmptyCanToNum(currentline[2])) {
          isDouble = true;
          break;
        }
      }

      for (let i = 1; i < lines.length; i++) {
        const obj = {} as CsvRecordItem;
        const currentline = lines[i].replace('\r', '').split(',');
        if (!currentline[0]) continue;
        obj.depth = Number(currentline[0]);
        obj.a1 = Number(currentline[1]);
        if (isDouble) obj.b1 = Number(currentline[2]);
        result.push(obj);
      }

      result.sort((a, b) => a.depth - b.depth);
      return result;
    }
  }

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback />
            <h2 className="content-toolbar-title">新增记录</h2>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '16px' }}>
        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
          {hole?.project?.name}
          {hole?.name}
        </div>
      </div>
      <div>
        <Card>
          <Form
            form={form}
            preserve={false}
            onFinish={onFinish}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col span={12}>
                <FormAlert {...alert} />
                <Form.Item
                  name="createAt"
                  label="监测时间"
                  rules={[{ required: true }]}
                >
                  <DatePicker showTime />
                </Form.Item>
                <Form.Item label="上传数据">
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginBottom: '8px',
                    }}
                  >
                    {hole?.mode === '正反测量模式' ? (
                      <a href="/模板（正反）.csv" download>
                        下载正反测量模式数据模板
                      </a>
                    ) : (
                      <a href="/模板（单边）.csv" download>
                        下载单边测量模式数据模板
                      </a>
                    )}
                  </div>
                  <Form.Item
                    name="dragger"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    noStyle
                  >
                    <Upload.Dragger
                      name="files"
                      action="/upload.do"
                      beforeUpload={beforeUpload}
                    >
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        补充数据后把文件拖拽到此区域
                      </p>
                      {/* <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p> */}
                    </Upload.Dragger>
                  </Form.Item>
                </Form.Item>
                <Row>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      保存
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={12}>
                <Form.Item label="数据预览">
                  {dataSource ? (
                    <Table
                      columns={cols}
                      dataSource={dataSource}
                      size="small"
                      pagination={false}
                      rowKey="depth"
                      scroll={{ y: 580 }}
                    />
                  ) : (
                    <Empty />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default CreateRecord;
