import { CloudUploadOutlined } from '@ant-design/icons';
import { useAntdTable } from 'ahooks';
import { Avatar, Space, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Device } from 'devices/device/devices.interface';
import { ProBreadcrumb } from 'pro';
import {
  CreateNavButton,
  DeleteButton,
  DetailNavButton,
  EditNavButton,
  RefreshButton,
  extraProps,
} from 'pro/table.pro';
import { Link } from 'react-router-dom';
import { deleteDevice, getDevices } from '../devices.service';

const DeviceList: React.FC = () => {
  const { tableProps, refresh } = useAntdTable(getDevices, {
    //refreshOnWindowFocus: true,
  });

  const columns: ColumnsType<Device> = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 100,
    },
    {
      title: '设备名称',
      render: (_, { id, name, avatar, no }) => (
        <Link to={id.toString()}>
          <Space>
            <Avatar
              shape="square"
              size={56}
              icon={<CloudUploadOutlined />}
              src={avatar ? avatar.downloadUrl : ''}
            />
            {name + (no ? `(${no})` : '')}
          </Space>
        </Link>
      ),
    },
    {
      title: '规格型号',
      dataIndex: 'model',
    },
    {
      title: '设备编号',
      dataIndex: 'no',
    },

    {
      title: '操作',
      key: 'action',
      render: (_, { id }) => (
        <Space size="middle">
          <DetailNavButton id={id} />
          <EditNavButton id={id} text="编辑资料" />
          <DeleteButton onDel={() => deleteDevice(id).then(refresh)} />
        </Space>
      ),
    },
  ];

  return (
    <>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <h2 className="content-toolbar-title">设备清单</h2>
          <Space>
            <CreateNavButton />
            <RefreshButton onRefresh={refresh} />
          </Space>
        </div>
      </div>
      <Table columns={columns} {...tableProps} {...extraProps(tableProps)} />
    </>
  );
};

export default DeviceList;
