import { CloudUploadOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Typography,
  message,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import type { UploadFile } from 'antd/es/upload/interface';
import Goback from 'pro/goBack/Goback';
import { CroppedInput } from 'files/CroppedInput';
import { UploadInput } from 'files/UploadInput';
import { FileMetadataDto } from 'files/files.interface';
import {
  convertFile,
  convertFileList,
  normFile,
  normImage,
} from 'files/files.service';
import { ProBreadcrumb } from 'pro';
import FormAlert, { FormAlertProps, getErrorMessage } from 'pro/FormAlert';
import { dayjsDate } from 'pro/proModal/form.pro';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UpdateDeviceDto } from '../devices.interface';
import { getDevice, updateDevice } from '../devices.service';
const { Text, Title } = Typography;

const DeviceUpdate: React.FC = () => {
  const [form] = Form.useForm();
  const deviceName = Form.useWatch('name', form);
  const [alert, setAlert] = useState<FormAlertProps>({ display: false });
  const { loading, runAsync } = useRequest(updateDevice, { manual: true });
  const navigate = useNavigate();

  const { deviceId } = useParams();
  if (!deviceId) {
    throw new Error('系统错误!');
  }
  const id = Number.parseInt(deviceId);
  useRequest(getDevice, {
    defaultParams: [id],
    onSuccess: (v) => {
      form.setFieldsValue({
        ...v,
        madeAt: dayjsDate(v.madeAt),
        runningAt: dayjsDate(v.runningAt),
      });
      setImage(convertFile(v.avatar));
      fileState[1](convertFileList(v.attachments));
    },
  });

  const onCancel = () => {
    setAlert({ display: false });
  };
  const onFinish = async (value: UpdateDeviceDto) => {
    try {
      setAlert({ display: false });

      await runAsync(id, value);
      onCancel();
      form.resetFields();
      navigate(-1);
      message.success('编辑成功!');
    } catch (err) {
      setAlert({ display: true, msg: getErrorMessage(err) });
    }
  };

  const [image, setImage] = useState<UploadFile<FileMetadataDto>>();
  const fileState = useState<UploadFile[]>();

  return (
    <div>
      <div className="content-toolbar">
        <ProBreadcrumb />
        <div className="content-toolbar-container">
          <div className="content-toolbar-goback">
            <Goback step={2} />
            <h2 className="content-toolbar-title">编辑设备</h2>
          </div>
        </div>
      </div>
      <div style={{ marginBottom: '16px' }}>
        <Row>
          <Col>
            <Avatar
              shape="square"
              size={56}
              icon={<CloudUploadOutlined />}
              src={image && image.response ? image.response.downloadUrl : ''}
            />
          </Col>
          <Col
            style={{
              padding: '0 0 0 24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Text type="secondary">
              {/* {projectName ? projectName : '工程名称'} */}
            </Text>
            <div style={{ fontSize: '24px', fontWeight: 'bold' }}>
              {deviceName ? deviceName : '新设备名称'}
            </div>
          </Col>
        </Row>
      </div>
      <div
        style={{
          maxWidth: '800px',
          // height: 'calc(100vh - 310px)',
          // overflowY: 'auto',
        }}
      >
        <Card>
          <Form
            layout="vertical"
            form={form}
            preserve={false}
            onFinish={onFinish}
            // labelCol={{ span: 6 }}
            // wrapperCol={{ span: 16 }}
          >
            <FormAlert {...alert} />
            <Title level={4} style={{ marginBottom: '16px' }}>
              设备登记卡
            </Title>
            <Row>
              <Col className="gutter-row" span={24}>
                {image?.response?.downloadUrl && (
                  <img
                    style={{ width: '100%' }}
                    src={image?.response?.downloadUrl}
                    alt="设备照片"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col className="gutter-row" span={16}>
                <Row>
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="name"
                      label="设备名称"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={24}>
                    <Form.Item
                      name="model"
                      label="规格型号"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col
                className="gutter-row"
                span={8}
                style={{
                  display: 'flex',
                  justifyContent: 'center' /* 水平居中 */,
                  alignItems: 'center' /* 垂直居中 */,
                }}
              >
                <Form.Item name="avatar" label="" getValueFromEvent={normImage}>
                  <CroppedInput image={image} setImage={setImage} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="no"
                  label="设备编号"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item name="madeAt" label="制造日期">
                  <DatePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col className="gutter-row" span={24}>
                <Form.Item name="summary" label="设备概要">
                  <TextArea rows={4} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={8}>
                <Form.Item
                  name="attachments"
                  label="附件"
                  getValueFromEvent={normFile}
                >
                  <UploadInput fileState={fileState} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col
                className="gutter-row"
                span={24}
                style={{ textAlign: 'right' }}
              >
                <Button type="primary" htmlType="submit" loading={loading}>
                  保存
                </Button>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};

export default DeviceUpdate;
