import { Tabs, Typography } from 'antd';
import { useTitle } from 'config/useTitleHook';
import NormalRegisterForm from './NormalRegisterForm';
import PhoneRegisterForm from './PhoneRegisterForm';
import { useNavigate } from 'react-router-dom';
import './index.css';

const { Title, Text, Link } = Typography;
/*
登录页面的关键要素
- 登录按钮的加载动画
- 登录成功后跳转回源地址
- 验证成功后保存 token
- 登录失败提示失败原因
*/
const Register: React.FC = () => {
  useTitle();
  const navigate = useNavigate();

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: 'url(/bgimg1.jpg)',
      }}
    >
      <div className="login-top">
        <div className="login-logo">
          <img src="/logo.png" alt="星链感知" style={{ width: '200px' }} />
        </div>
        <Title level={4} style={{ paddingTop: '8px', fontWeight: 'normal' }}>
          注册
        </Title>
      </div>
      <div className="login-content">
        <div className="login-adv">
          <Title level={1}>星链感知</Title>
          <Title level={3}>新一代智慧传感器</Title>
        </div>
        <div className="login-form-warp">
          <div className="go-to-login" onClick={() => navigate('/login')}>
            <div className="go-to-login-triangle" />
            <span className="go-to-login-span">登录</span>
          </div>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: '1',
                label: `账号注册`,
                children: <NormalRegisterForm />,
              },
              {
                key: '2',
                label: `手机号注册`,
                children: <PhoneRegisterForm />,
              },
            ]}
          />
        </div>
      </div>
      <div className="login-buttom">
        <div className="login-buttom-remark">
          <Text type="secondary">
            <Link
              href="https://beian.miit.gov.cn/"
              target="_blank"
              className="login-buttom-remark-beian"
            >
              苏ICP备2024095984号
            </Link>{' '}
            | 星链感知 | 版权所有：常州市金坛中测传感器科技有限公司 |
            小梨云技术支持
          </Text>
        </div>
      </div>
    </div>
  );
};

export default Register;
